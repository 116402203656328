import {ApiMenuItem, ApiMillesime} from '@repo/api/model';

export const getUniqueMillesime = (menuItems: ApiMenuItem[]): ApiMillesime[] => {
    const millesimeMap = new Map<number, ApiMillesime>();
    for (let i = 0; i < menuItems.length; i++){
        const menuItem = menuItems[i]!;
        if (menuItem.millesime && menuItem.millesime.year) {
            millesimeMap.set(menuItem.millesime.year, menuItem.millesime);
        }
    }
    return Array.from(millesimeMap.values()).sort((a, b) => a.year - b.year);
};