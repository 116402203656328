import {ApiMenu, ApiMenuItem} from '@repo/api/model';
import {ComboBoxItem} from '@repo/ui';
import {applyFilter} from "./api/apply-filter";
import {Log} from '@repo/utils';

export const applyWineGrapesFilters = (menu: ApiMenu, wineGrapes: ComboBoxItem[]): ApiMenu => {
    if (!wineGrapes.length) {
        return menu;
    }

    Log('applying wine grapes filter', wineGrapes.map(grape => grape.value));
    return applyFilter(menu, (item: ApiMenuItem) => {
        // the item grapes must include all the selected wineGrapes
        return wineGrapes.every(wineGrape => {
            return item.grapes?.some(g => g.name === wineGrape.value)
        });
    });
};
