import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { BackofficeAppState, PublishedInMenu } from './app-state';
import { initialBackofficeState } from './initial-state';
import { BackofficeAppStateReducers } from './backoffice-state-reducers';
import { backOfficeApi } from './api/backoffice-api';
import { ApiMenuItem, ApiMenuItemType } from '@repo/api/model';

export const backofficeStateSlice: Slice<BackofficeAppState, BackofficeAppStateReducers, 'backOfficeAppState'> = createSlice({
    name: 'backOfficeAppState',
    initialState: initialBackofficeState,
    reducers: {
        setSelectedProductDisplayType: (state, action) => {
            state.selectedProductDisplayType = action.payload;
        },
        setItemEditFlag: (state, action) => {
            state.itemEditFlag = action.payload;
        },
        setSelectedRestaurant: (state, action) => {
            state.selectedRestaurant = action.payload;
        },
        setBreadcrumbs: (state, action) => {
            state.breadcrumbs = action.payload;
        },
        setSelectedMenu: (state, action) => {
            state.selectedMenu = action.payload;
        },
        setSelectedEditableProduct: (state, action: PayloadAction<ApiMenuItem | null>) => {
            state.selectedEditableProduct = action.payload;
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
        },
        setMenuItemSearchQuery: (state, action) => {
            state.menuItemSearchQuery = action.payload;
        },
        setProductsSearchQuery: (state, action) => {
            state.productsSearchQuery = action.payload;
        },
        resetItems: (state) => {
            state.restaurantItems = [];
            state.groupItems = [];
            state.globalItems = [];
        },
        setAddSectionDialogState: (state, action) => {
            state.addSectionDialogState = action.payload;
        },
        setAddSubSectionDialogState: (state, action) => {
            state.addSubsectionDialogState = action.payload;
        },
        setEditSectionDialogState: (state, action) => {
            state.editSectionDialogState = action.payload;
        },
        setEditSubsectionDialogState: (state, action) => {
            state.editSubsectionDialogState = action.payload;
        },
        setDeleteSectionDialogState: (state, action) => {
            state.deleteSectionDialogState = action.payload;
        },
        setDeleteSubsectionDialogState: (state, action) => {
            state.deleteSubsectionDialogState = action.payload;
        },
        setPublishMenuItemDialogState: (state, action) => {
            state.publishMenuItemDialogState = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(backOfficeApi.endpoints.getRestaurantMenuItems.matchFulfilled, (state, action) => {
            state.restaurantItems = action.payload;
        });
        builder.addMatcher(backOfficeApi.endpoints.getGroupMenuItems.matchFulfilled, (state, action) => {
            state.groupItems = action.payload;
        });
        builder.addMatcher(backOfficeApi.endpoints.getGlobalMenuItems.matchFulfilled, (state, action) => {
            state.globalItems = action.payload;
        });
        builder.addMatcher(backOfficeApi.endpoints.getRestaurantMenus.matchFulfilled, (state, action) => {
            const menus = action.payload?.data;
            if (!menus) {
                return;
            }
            type ItemId = string;
            const publishedInMenus: Record<ItemId, PublishedInMenu[]> = {};

            const addMenuToPublishedInMenus = (item: any, menu: any) => {
                if (!publishedInMenus[item.id]) {
                    publishedInMenus[item.id] = [];
                }
                const isInPublishedInMenus = publishedInMenus[item.id]!.find(publishedInMenu => publishedInMenu.id === menu.id);
                if (isInPublishedInMenus) {
                    return;
                }
                publishedInMenus[item.id]!.push({
                    id: menu.id,
                    name: menu.name,
                    isVisible: !!item.isVisible
                });
            };

            for (const menu of menus) {
                for (const section of menu.sections) {
                    for (const item of section.items ?? []) {
                        if (item.type === ApiMenuItemType.SUB_ITEM) {
                            const items = item.items;
                            if (items) {
                                for (const item of items) {
                                    addMenuToPublishedInMenus(item, menu);
                                }
                            }
                        }
                        if (item.type !== ApiMenuItemType.SUB_ITEM) {
                            addMenuToPublishedInMenus(item, menu);
                        }

                    }
                }
            }
            state.itemsPublishedIn = publishedInMenus;
        });
        builder.addMatcher(backOfficeApi.endpoints.getProductEditorSelectorOptions.matchFulfilled, (state, action) => {
            state.productEditorSelectorOptions = action.payload.data;
        });
    }
});

export const {
    setSelectedRestaurant,
    setSelectedTab,
    setBreadcrumbs,
    setSelectedMenu,
    setSelectedEditableProduct,
    setSelectedProductDisplayType,
    setMenuItemSearchQuery,
    resetItems,
    setAddSectionDialogState,
    setAddSubSectionDialogState,
    setEditSectionDialogState,
    setEditSubsectionDialogState,
    setDeleteSectionDialogState,
    setDeleteSubsectionDialogState,
    setPublishMenuItemDialogState,
    setProductsSearchQuery,
    setItemEditFlag
} = backofficeStateSlice.actions;

export const backofficeStateReducer = backofficeStateSlice.reducer;
