import {z} from 'zod';
import {TranslatableSchema} from './translatable-schema';
import {ImageSchema} from './image';
import {MenuTemplateSchema} from './menu-template';
import {MenuSectionSchema} from './menu-section';

export const MenuSchema = z.object({
    id: z.string(),
    restaurant: z.object({
        id: z.string(),
        name: z.string(),
    }).optional(),
    name: z.lazy(() => z.array(TranslatableSchema)),
    description: z.lazy(() => z.array(TranslatableSchema)).optional(),
    note: z.string().optional(),
    restaurantId: z.string(),
    image: ImageSchema.optional(),
    menuTemplate: MenuTemplateSchema,
    sections: z.array(MenuSectionSchema),
    position: z.number().optional(),
    createdAt: z.string().optional(),
    updatedAt: z.string().optional()
});

export type ApiMenu = z.infer<typeof MenuSchema>;
