import {ApiMenu} from '@repo/api/model';
import {ComboBoxItem} from '@repo/ui';
import {applyFilter} from "./api/apply-filter";
import {Log} from '@repo/utils';

export const applyLabelFilter = (menu: ApiMenu, labelFilters: ComboBoxItem[]): ApiMenu => {

    if (!labelFilters.length) {
        return menu;
    }

    Log('applying label filter', labelFilters.map(label => label.value));

    return applyFilter(menu, (item) => {
        return labelFilters.every(labelFilter => {
            return item.label?.some(label => label.name === labelFilter.value);
        });
    });
}
