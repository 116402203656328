import {ApiMenu} from '@repo/api/model';
import {ComboBoxItem} from '@repo/ui';
import {applyFilter} from "./api/apply-filter";
import {Log} from '@repo/utils';

export const applyWineCategoryFilters = (menu: ApiMenu, wineCategories: ComboBoxItem[]): ApiMenu => {
    if (!wineCategories.length) {
        return menu;
    }
    Log('applying wine category filter', wineCategories.map(category => category.value));
    return applyFilter(menu, (item) => {
        return wineCategories.every(wineCategory => {
            return item.wineCategory?.name === wineCategory.value
        });
    });
};
