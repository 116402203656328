import { z } from "zod";
import { TranslatableSchema } from "./translatable-schema";
import { ImageSchema } from "./image";

export const DomainSchema = z.object({
    id: z.string(),
    nameId: z.string(),
    name: z.array(TranslatableSchema),
    description: z.array(TranslatableSchema).optional(),
    picture: ImageSchema.optional(),
    logo: ImageSchema.optional(),
});

export type ApiDomain = z.infer<typeof DomainSchema>;