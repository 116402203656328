import {z} from 'zod';

export const SubscriberSchema = z.object({
    email: z.string().email(),
    restaurantId: z.string(),
    restaurantSlug: z.string(),
    isEmailVerified: z.boolean(),
    // an item id might indicate that the subscriber is interested in a specific item
    itemId: z.string().optional(),
    menuId: z.string().optional(),
    source: z.enum(['menu_item_card_share']),
});

export type ApiSubscriber = z.infer<typeof SubscriberSchema>;