import {z} from 'zod';

export const MenuTemplateSchema = z.lazy(() => z.object({
    id: z.string(),
    restaurant: z.object({
        id: z.string(),
        name: z.string(),
    }).optional(),
    restaurantName: z.string(),
    restaurantId: z.string().optional(),
    menuType: z.string(),
    name: z.string(),
    wineCategoryFilter: z.boolean(),
    foodCategoryFilter: z.boolean(),
    nameFilter: z.boolean(),
    priceFilter: z.boolean(),
    caloriesFilter: z.boolean(),
    bottleGlassFilter: z.boolean(),
    allergensFilter: z.boolean(),
    additivesFilter: z.boolean(),
    featuredFilter: z.boolean(),
    grapesFilter: z.boolean(),
    wineTypeFilter: z.boolean(),
    wineStyleFilter: z.boolean(),
    regionFilter: z.boolean(),
    millesimeFilter: z.boolean(),
    aromaFilter: z.boolean(),
    labelFilter: z.boolean(),
}));

export type ApiMenuTemplate = z.infer<typeof MenuTemplateSchema>;