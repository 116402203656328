import {ApiMenu} from '@repo/api/model';
import {ComboBoxItem} from '@repo/ui';
import {applyFilter} from "./api/apply-filter";
import { Log } from '@repo/utils';

export const applyWineAromaFilter = (menu: ApiMenu, aromas: ComboBoxItem[]): ApiMenu => {
    if (!aromas.length) {
        return menu;
    }

    Log('applying wine aroma filter', aromas.map(aroma => aroma.value));
    return applyFilter(menu, (item) => {
        return aromas.every(aromaFilter => {
            return item.aroma?.some(a => a.name === aromaFilter.value);
        });
    });
}
