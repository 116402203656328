import {ApiMenu, ApiMenuItem} from '@repo/api/model';
import {applyFilter} from './api/apply-filter';
import {Log} from '@repo/utils';

export const applyPriceFilter = (menu: ApiMenu, price?: number, isPerGlass: boolean = false): ApiMenu => {
    if (price === undefined || price === -1) {
        return menu;
    }

    Log('applying price filter', price, isPerGlass);
    return applyFilter(menu, (item: ApiMenuItem) => {
        if (price === undefined) {
            return true;
        }

        if (isPerGlass) {
            return !!item.pricePerGlass && item.pricePerGlass <= price;
        }
        return !!item.price && item.price <= price;
    });
};
