import {ApiMenu} from '@repo/api/model';
import {ComboBoxItem} from '@repo/ui';
import {applyFilter} from "./api/apply-filter";
import {Log} from '@repo/utils';

export const applyWineStyleFilters = (menu: ApiMenu, wineStyles: ComboBoxItem[]): ApiMenu => {
    if (!wineStyles.length) {
        return menu;
    }

    Log('applying wine style filter', wineStyles.map(style => style.value));

    return applyFilter(menu, (item) => {
        if (wineStyles.every(style => item.style?.some(s => s.name === style.value))) {
            return true;
        }
        return false;
    });
};
