import {ApiMenuItem} from '@repo/api/model';

export const getPriceRanges = (menuItems: ApiMenuItem[]): {
    minPrice: number,
    maxPrice: number,
    minPricePerGlass: number,
    maxPricePerGlass: number
} => {
    const prices = menuItems.map(menuItem => menuItem.price ?? 0);
    const pricePerGlass = menuItems.map(menuItem => menuItem.pricePerGlass ?? 0);
    return {
        minPrice: Math.min(...prices),
        maxPrice: Math.max(...prices),
        minPricePerGlass: Math.min(...pricePerGlass),
        maxPricePerGlass: Math.max(...pricePerGlass),
    };
}