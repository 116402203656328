import {z} from 'zod';
import {TranslatableSchema} from './translatable-schema';
import {MenuItemSchema} from './menu-item';

export const MenuSectionSchema = z.object({
    id: z.string(),
    name: z.array(TranslatableSchema),
    description: z.array(TranslatableSchema).optional(),
    nameId: z.string(),
    position: z.number(),
    restaurantId: z.string(),
    menuId: z.string(),
    items: z.array(MenuItemSchema)
});

export type ApiMenuSection = z.infer<typeof MenuSectionSchema>;