import {configureStore} from '@reduxjs/toolkit';
import {authApi} from './api/auth-api';
import {pushApi} from './api/push-api';
import {appSliceReducer} from './app-state-slice';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import storage from 'redux-persist/lib/storage';
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import {AppState, BackofficeAppState} from './app-state';
import {restaurantApi} from './api/restautant-api';
import {backOfficeApi} from './api/backoffice-api';
import {restaurantMenuApi} from './api/restaurant-menu-api';
import {backofficeStateReducer} from './backoffice-state-slice';
import {PersistConfig} from 'redux-persist/es/types';

const appStatePersistConfig: PersistConfig<AppState> = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: [
        'appliedFilters',
        'lang',
        'menuTemplate',
        'restaurant',
        'menu',
        'selectedMenuId',
        'currency',
    ]
};

const backofficePersistConfig: PersistConfig<BackofficeAppState> = {
    key: 'backofficeRoot',
    storage,
    stateReconciler: autoMergeLevel2
};

const persistedAppSliceReducer = persistReducer<AppState>(appStatePersistConfig, appSliceReducer);
const persistedBackOfficeSliceReducer = persistReducer<BackofficeAppState>(backofficePersistConfig, backofficeStateReducer);

// @ts-ignore
export const store = configureStore({
        reducer: {
            [authApi.reducerPath]: authApi.reducer,
            [pushApi.reducerPath]: pushApi.reducer,
            [restaurantApi.reducerPath]: restaurantApi.reducer,
            [restaurantMenuApi.reducerPath]: restaurantMenuApi.reducer,
            [backOfficeApi.reducerPath]: backOfficeApi.reducer,
            appState: persistedAppSliceReducer,
            backOfficeAppState: persistedBackOfficeSliceReducer
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: true,
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
                immutableCheck: false
            })
                .concat(authApi.middleware)
                .concat(pushApi.middleware)
                .concat(restaurantApi.middleware)
                .concat(restaurantMenuApi.middleware)
                .concat(backOfficeApi.middleware),
    devTools: true
    }
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);