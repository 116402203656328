import {ApiMenu} from '@repo/api/model';
import {applyFilter} from "./api/apply-filter";
import {Log} from '@repo/utils';

export const applyFeaturedFilter = (menu: ApiMenu, isFeatured?: boolean): ApiMenu => {
    // if the filter is not set, return the menu
    if (!isFeatured) {
        return menu;
    }

    Log('applying featured filter', isFeatured);
    return applyFilter(menu, (item) => {
        return item.isFeatured ?? false;
    });
};
