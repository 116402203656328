import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {ResponseEnvelope} from '@repo/api/response';
import {UpdatePushNotificationTokenRequestBody} from '../../api/push/token/route-types';

export const pushApi = createApi({
    reducerPath: 'pushApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/push',
        validateStatus: () => true,
        prepareHeaders: (headers) => {
            headers.set('content-type', 'application/json');
            headers.set('accept', 'application/json');
            headers.set('x-api-key', process.env.NEXT_PUBLIC_API_KEY || '');
            return headers;
        }
    }),
    endpoints: (builder) => ({
        updateUserPushNotificationToken: builder.mutation<ResponseEnvelope, UpdatePushNotificationTokenRequestBody>({
            query: (body) => ({
                url: '/token',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useUpdateUserPushNotificationTokenMutation
} = pushApi;