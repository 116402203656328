import {z} from 'zod';
import {TranslatableSchema} from './translatable-schema';
import {SupportedLanguageSchema} from './supported-language';
import {RestaurantGroupSchema} from './restaurant-goup';
import {ImageSchema} from './image';
import {MenuThemeSchema} from './menu-theme';
import {ApiMenu, MenuSchema} from './menu';

const BaseRestaurantSchema = z.object({
    id: z.string(),
    name: z.string(),
    description: z.array(TranslatableSchema),
    slug: z.string(),
    bookingLink: z.string().optional(),
    currency: z.string(),
    defaultLanguage: SupportedLanguageSchema,
    supportedLanguages: z.array(SupportedLanguageSchema),
    group: RestaurantGroupSchema,
    logo: ImageSchema.optional(),
    lightTheme: MenuThemeSchema,
    darkTheme: MenuThemeSchema,
});

export type ApiRestaurant = z.infer<typeof BaseRestaurantSchema> & {
    menus: ApiMenu[]
}

export const RestaurantSchema = BaseRestaurantSchema.extend({
    menus: z.lazy(() => z.array(MenuSchema))
});
