import { ApiMenuItem, ApiWineCategory } from '@repo/api/model';

export const getUniqueWineCategories = (menuItems?: ApiMenuItem[]) => {
    if (!menuItems || !menuItems.length) {
        return [];
    }

    return menuItems.reduce((acc: ApiWineCategory[], item) => {
        const { wineCategory } = item;
        if (!wineCategory || !wineCategory.name) {
            return acc;
        }
        if (!acc.find(i => i.name === wineCategory.name)) {
            acc.push(wineCategory);
        }
        return acc;
    }, [])
        // sort alphabetically from A to Z
        .sort((a, b) => a.name.localeCompare(b.name));
};
