import { ApiMenu } from '@repo/api/model';
import { applyFilter } from "./api/apply-filter";
import { Log } from '@repo/utils';

export const applyNameFilter = (menu: ApiMenu, nameFilter: string): ApiMenu => {
    if (!nameFilter) {
        return menu;
    }
    return applyFilter(menu, (item) => {
        if (item.name?.find(name => name.value.toLowerCase().includes(nameFilter.toLowerCase()))) {
            return true;
        }
        return false;
    });
};
