import { ApiLabel, ApiMenuItem } from '@repo/api/model';

export const getUniqueLabels = (menuItems?: ApiMenuItem[]): ApiLabel[] => {
    if (!menuItems || !menuItems.length) {
        return [];
    }

    return menuItems.reduce((acc: ApiLabel[], item) => {
        const { label: labels } = item;
        if (!labels) {
            return acc;
        }

        for (const label of labels) {
            const existingLabel = acc.find(l => l.name === label.name);
            if (!existingLabel) {
                acc.push(label);
            }
        }

        return acc;
    }, [])
        .sort((a, b) => a.name.localeCompare(b.name));
};