import {z} from 'zod';

export const SupportedLanguageSchema = z.enum(['EN', 'RO', 'ES', 'FR', 'IT', 'DE', 'HU']);

export enum ApiSupportedLanguage {
    EN = 'EN',
    RO = 'RO',
    ES = 'ES',
    FR = 'FR',
    IT = 'IT',
    DE = 'DE',
    HU = 'HU',
}