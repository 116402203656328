import {ApiMenu} from '@repo/api/model';
import {ComboBoxItem} from '@repo/ui';
import {applyFilter} from './api/apply-filter';
import {Log} from '@repo/utils';

export const applyFoodCategoryFilters = (menu: ApiMenu, foodCategoryAssociations: ComboBoxItem[]): ApiMenu => {
    if (!foodCategoryAssociations.length) {
        return menu;
    }
    Log('applying food category filter', foodCategoryAssociations.map(category => category.value));
    const foodCategoryAssociationsSet = new Set(foodCategoryAssociations.map(category => category.value));
    return applyFilter(menu, (item) => {

        if (!item.foodCategoryAssociation) {
            return true;
        }
        // items item.foodCategoryAssociation must include all selected food categories
        if (foodCategoryAssociationsSet.size === item.foodCategoryAssociation.filter(association => foodCategoryAssociationsSet.has(association.name)).length) {
            return true;
        }
        return false;
    });
};
