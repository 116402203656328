import { ApiMenuItem, ApiStyle } from '@repo/api/model';

export const getUniqueWineStyles = (menuItems?: ApiMenuItem[]) => {
    if (!menuItems || !menuItems.length) {
        return [];
    }

    return menuItems.reduce((acc: ApiStyle[], item) => {
        const { style } = item;
        if (!style) {
            return acc;
        }

        return style.reduce((acc, style) => {
            if (style.name && !acc.find(i => i.name === style.name)) {
                acc.push(style);
            }
            return acc;
        }, acc);
    }, [])
        .sort((a, b) => a.name.localeCompare(b.name));
};
