import {z} from 'zod';
import {ColorSchema} from './color';

export const MenuThemeSchema = z.object({
    name: z.string(),
    background: ColorSchema,
    foreground: ColorSchema,
    button: ColorSchema,
    buttonText: ColorSchema,
    dangerButton: ColorSchema,
    dangerButtonText: ColorSchema,
    disabledButton: ColorSchema,
    disabledButtonText: ColorSchema,
});

export type ApiMenuTheme = z.infer<typeof MenuThemeSchema>;