import { ApiGrape, ApiMenuItem } from '@repo/api/model';

export const getUniqueGrapes = (menuItems?: ApiMenuItem[]) => {
    if (!menuItems || !menuItems.length) {
        return [];
    }

    return menuItems.reduce((acc: ApiGrape[], item) => {
        const { grapes } = item;
        if (!grapes || !grapes.length) {
            return acc;
        }

        return grapes.reduce((acc, grape) => {
            if (grape.name && !acc.find(i => i.name === grape.name)) {
                acc.push(grape);
            }
            return acc;
        }, acc);
    }, [])
        .sort((a, b) => a.name.localeCompare(b.name));
};
