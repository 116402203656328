import { ApiMenuItem, ApiRegion } from '@repo/api/model';

export const getUniqueRegions = (menuItems: ApiMenuItem[]) => {
    const uniqueRegions: ApiRegion[] = [];
    for (let i = 0; i < menuItems.length; i++) {
        const item = menuItems[i]!;
        const { region } = item;
        if (!region || !region.id) {
            continue;
        }
        if (!uniqueRegions.find(i => i.name === region.name)) {
            uniqueRegions.push(region);
        }
    }
    return uniqueRegions
        .sort((a, b) => a.name.localeCompare(b.name));
};
