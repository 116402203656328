import {ApiMenu} from '@repo/api/model';
import {ComboBoxItem} from '@repo/ui';
import {applyFilter} from './api/apply-filter';
import {Log} from '@repo/utils';

export const applyRegionFilter = (menu: ApiMenu, regionFilter: {
    countries: ComboBoxItem[];
    regions: ComboBoxItem[];
}): ApiMenu => {
    const {countries, regions} = regionFilter;
    if (!countries.length && !regions.length) {
        return menu;
    }

    Log('applying region filter', countries.map(country => country.value), regions.map(region => region.value));
    return applyFilter(menu, (item) => {
        const {region, country} = item;
        if (regions.length) {
            if (!region) {
                return false;
            }
            const isItemInRegion = regions.find((r: ComboBoxItem) => {
                return r.value === region.name;
            });
            if (!isItemInRegion) {
                return false;
            }
        }

        if (countries.length) {
            if (!country) {
                return false;
            }
            const isItemInCountry = countries.find((c: ComboBoxItem) => {
                return c.value === country.name;
            });
            if (!isItemInCountry) {
                return false;
            }
        }
        return true;
    });
};
