import {ApiMenu} from '@repo/api/model';
import {applyFilter} from './api/apply-filter';
import {Log, LogIf} from '@repo/utils';

export const applyBottleFilter = (menu: ApiMenu, isByGlass: boolean): ApiMenu => {
    if (!isByGlass) {
        return menu;
    }
    Log('applying bottle/glass filter. isByGlass', isByGlass);
    return applyFilter(menu, (item) => {
        LogIf(item.id === 'LqWejmmqK5SeaGlqqZeF', 'item', item, isByGlass);
        if (isByGlass) {
            if (item.isVisibleForPricePerGlass) {
                LogIf(item.id === 'LqWejmmqK5SeaGlqqZeF', `the item is visible for price per glass`);
                return true;
            }
            LogIf(item.id === 'LqWejmmqK5SeaGlqqZeF', `the item is not visible for price per glass`);
            return false;
        }

        if (item.isVisibleForPrice) {
            LogIf(item.id === 'LqWejmmqK5SeaGlqqZeF', `the item is visible for price`);
            return true;
        }
        return false;
    });
};
