import {ApiMenu, ApiMenuItem} from '@repo/api/model';
import {ComboBoxItem} from '@repo/ui';
import {applyFilter} from "./api/apply-filter";
import {Log} from '@repo/utils';

export const applyMillesimeFilter = (menu: ApiMenu, millesime: ComboBoxItem[]): ApiMenu => {
    if (!millesime.length) {
        return menu;
    }

    Log('applying millesime filter', millesime.map(millesime => millesime.value));
    return applyFilter(menu, (item: ApiMenuItem) => {
        return millesime.some(m => item.millesime?.year === Number(m.value));
    });
}
