import { ApiFoodCategory, ApiMenuItem } from '@repo/api/model';

export const getUniqueFoodCategoryAssociations = (menuItems?: ApiMenuItem[]) => {
    if (!menuItems || !menuItems.length) {
        return [];
    }

    const uniqueCategories: ApiFoodCategory[] = [];

    for (const item of menuItems) {
        if (!item.foodCategoryAssociation || !item.foodCategoryAssociation.length) {
            continue;
        }

        for (const association of item.foodCategoryAssociation) {
            const existingCategory = uniqueCategories.find(i => i.name === association.name);
            if (!existingCategory) {
                uniqueCategories.push(association);
            }
        }
    }
    const sortedCategories = uniqueCategories.sort((a, b) => a.name.localeCompare(b.name));

    return sortedCategories;
};