import {z} from 'zod';

// hex = '';
// hsv: {h: number, s: number, v: number} = {h: 0, s: 0, v: 0};
// rgb: {r: number, g: number, b: number} = {r: 0, g: 0, b: 0};

export const ColorSchema = z.object({
    hex: z.string(),
    hsv: z.object({
        h: z.number(),
        s: z.number(),
        v: z.number()
    }),
    rgb: z.object({
        r: z.number(),
        g: z.number(),
        b: z.number()
    })
});

export type ApiColor = z.infer<typeof ColorSchema>;