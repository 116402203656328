import {ApiMenu, ApiMenuItem, ApiMenuItemType} from '@repo/api/model';

// eslint-disable-next-line no-unused-vars
export type FilterFunction = (item: ApiMenuItem) => boolean;

export const applyFilter = (menu: ApiMenu, filterFunction: FilterFunction): ApiMenu => {
    if (!menu.sections) {
        return menu;
    }
    const filteredMenu: ApiMenu = {...menu, sections: []};

    for (const section of menu.sections) {
        const filteredItems = section.items?.reduce((acc, item) => {
            if (item.type !== ApiMenuItemType.SUB_ITEM && filterFunction(item)) {
                acc.push(item);
            }
            if (item.type === ApiMenuItemType.SUB_ITEM && item.items) {
                const filteredSubItems = item.items.filter(filterFunction);
                if (filteredSubItems.length > 0) {
                    acc.push({...item, items: filteredSubItems});
                }
            }
            return acc;
        }, [] as ApiMenuItem[]);

        if (filteredItems && filteredItems.length > 0) {
            const filteredSection = {...section, items: filteredItems};
            filteredMenu.sections.push(filteredSection);
        }
    }

    return filteredMenu;
};
