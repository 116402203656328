import { ApiAroma, ApiMenuItem } from '@repo/api/model';

export const getUniqueAromas = (menuItems?: ApiMenuItem[]): ApiAroma[] => {
    if (!menuItems || !menuItems.length) {
        return [];
    }

    return menuItems.reduce((acc: ApiAroma[], item) => {
        const { aroma } = item;
        if (!aroma || !aroma.length) {
            return acc;
        }

        return aroma.reduce((acc, aroma) => {
            if (aroma.name && !acc.find(i => i.name === aroma.name)) {
                acc.push(aroma);
            }
            return acc;
        }, acc);
    }, [])
        .sort((a, b) => a.name.localeCompare(b.name));
};
